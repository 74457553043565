import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import BannerIntro from "../components/BannerIntro"
import Seo from "../components/Seo"
import { GatsbyImage } from "gatsby-plugin-image"

const Services = props => {
  const servicesData = props.data.wpPage.servicesFields
  const pageData = props.data.wpPage
  const seoData = props.data.wpPage.seo
  console.log(servicesData);

  return (
    <Layout extraClass="services" pageId={pageData.databaseId}>
      <Seo
        title={seoData.title}
        description={seoData.metaDesc}
        canonical={seoData.canonical}
        keywords={seoData.metaKeywords}
      />
      <BannerIntro
        imageUrl={servicesData.bannerImage.localFile.childImageSharp.gatsbyImageData}
        altText={servicesData.bannerImage.altText}
        headline={servicesData.headline}
        copy={servicesData.copy}
      />
      <div className="container">
        <div className="overview">
          <div className="copy">
            <p>{servicesData.imageWithColorBlock.copy}</p>
          </div>
          <GatsbyImage
            image={servicesData.imageWithColorBlock.image.localFile.childImageSharp.gatsbyImageData}
            alt={servicesData.imageWithColorBlock.image.altText}
          />
        </div>
        <div className="all-services">
          {props.data.allWpService.edges.map(pie => (
            <>
              {pie.node.singleService && (
                <a href={`/services/${pie.node.slug}`} className="service">
                  <img
                    src={pie.node.singleService.icon.sourceUrl}
                    alt={pie.node.singleService.icon.altText}
                  />
                  <h2>{pie.node.title}</h2>
                  <p>{pie.node.singleService.blurb}</p>
                  <p className="red hover hover-1">Learn More</p>
                </a>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="footer-image">
        <GatsbyImage
          image={servicesData.cta.backgroundImage.localFile.childImageSharp.gatsbyImageData}
          alt={servicesData.cta.backgroundImage.altText}
        />
      </div>
    </Layout>
  )
}

export default Services

export const servicesQuery = graphql`
  query servicesQuery {
    wpPage(databaseId: { eq: 8 }) {
      title
      databaseId
      seo {
        title
        canonical
        metaDesc
        metaKeywords
    }
      servicesFields {
        copy
        headline
        bannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        imageWithColorBlock {
          copy
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        cta {
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    allWpService {
      edges {
        node {
          slug
          title
          singleService {
            blurb
            icon {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`
